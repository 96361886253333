// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-faqs-customer-js": () => import("./../../../src/pages/faqs-customer.js" /* webpackChunkName: "component---src-pages-faqs-customer-js" */),
  "component---src-pages-faqs-merchant-js": () => import("./../../../src/pages/faqs-merchant.js" /* webpackChunkName: "component---src-pages-faqs-merchant-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-request-js": () => import("./../../../src/pages/service-request.js" /* webpackChunkName: "component---src-pages-service-request-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-dato-article-js": () => import("./../../../src/templates/datoArticle.js" /* webpackChunkName: "component---src-templates-dato-article-js" */),
  "component---src-templates-dato-blog-js": () => import("./../../../src/templates/datoBlog.js" /* webpackChunkName: "component---src-templates-dato-blog-js" */),
  "component---src-templates-dato-feature-page-js": () => import("./../../../src/templates/datoFeaturePage.js" /* webpackChunkName: "component---src-templates-dato-feature-page-js" */),
  "component---src-templates-dato-page-js": () => import("./../../../src/templates/datoPage.js" /* webpackChunkName: "component---src-templates-dato-page-js" */)
}

